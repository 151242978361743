import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Back from '../components/Back';

const Datenschutz = (props) => (
	<Layout className={props.transitionStatus}>
		<SEO title="Datenschutz" theme="dark" />
		<h1>Datenschutz</h1>

		<section className="separator separator--bottom helper--tight">
			<p>Wir freuen uns über Ihr Interesse an unserer Webseite. Der Schutz Ihrer Privatsphäre ist für uns sehr wichtig. Nachstehend informieren wir Sie ausführlich über den Umgang mit Ihren Daten.</p>
			<p>
				<strong>1. ZUGRIFFSDATEN UND HOSTING</strong><br />
				Sie können unsere Webseiten besuchen, ohne Angaben zu Ihrer Person zu machen. Bei jedem Aufruf einer Webseite speichert der Webserver lediglich automatisch ein sogenanntes Server-Logfile, das z.B. den Namen der angeforderten Datei, Ihre IP-Adresse, Datum und Uhrzeit des Abrufs, übertragene Datenmenge und den anfragenden Provider (Zugriffsdaten) enthält und den Abruf dokumentiert.
			</p>
			<p>
				Diese Zugriffsdaten werden ausschließlich zum Zwecke der Sicherstellung eines störungsfreien Betriebs der Seite sowie der Verbesserung unserer Online-Präsenz ausgewertet. Dies dient gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO der Wahrung unserer im Rahmen einer Interessensabwägung überwiegenden berechtigten Interessen an einer korrekten Darstellung unserer Online-Präsenz. Alle Zugriffsdaten werden spätestens sieben Tage nach Ende Ihres Seitenbesuchs gelöscht.
			</p>
		</section>
		<section className="separator separator--bottom helper--tight">
			<p>
				<strong>HOSTING</strong><br />
				Die Dienste zum Hosting und zur Darstellung der Webseite werden teilweise durch unsere Dienstleister im Rahmen einer Verarbeitung in unserem Auftrag erbracht. Soweit im Rahmen der vorliegenden Datenschutzerklärung nichts anderes erläutert wird, werden alle Zugriffsdaten sowie alle Daten, die in dafür vorgesehenen Formularen auf dieser Webseite erhoben werden, auf ihren Servern verarbeitet. Bei Fragen zu unseren Dienstleistern und der Grundlage unserer Zusammenarbeit mit ihnen wenden Sie sich bitte an die in dieser Datenschutzerklärung beschriebenen Kontaktmöglichkeit.
			</p>
		</section>
		<section className="separator separator--bottom helper--tight">
			<p>
				<strong>2. DATENERHEBUNG UND -VERWENDUNG BEI KONTAKTAUFNAHME</strong><br />
				Wir erheben personenbezogene Daten, wenn Sie uns diese bei einer Kontaktaufnahme mit uns (z.B. per Kontaktformular oder E-Mail) freiwillig mitteilen. Pflichtfelder werden als sol- che gekennzeichnet, da wir in diesen Fällen die Daten zwingend zur Bearbeitung Ihrer Kon- taktaufnahme benötigen und Sie ohne deren Angabe die Kontaktaufnahme nicht versenden können. Welche Daten erhoben werden, ist aus den jeweiligen Eingabeformularen ersichtlich. Wir verwenden die von ihnen mitgeteilten Daten zur Bearbeitung Ihrer Anfragen gemäß Art. 6 Abs. 1 S. 1 lit. b DSGVO. Nach vollständiger Abwicklung Ihrer Kundenanfrage werden Ihre Daten gelöscht, sofern Sie nicht ausdrücklich in eine weitere Nutzung Ihrer Daten gemäß Art. 6 Abs. 1 S. 1 lit. a DSGVO eingewilligt haben oder wir uns eine darüber hinausgehende Datenverwendung vorbehalten, die gesetzlich erlaubt ist und über die wir Sie in dieser Erklärung informieren.
			</p>
		</section>
		<section className="separator separator--bottom helper--tight">
			<p>
				<strong>3. WERBUNG PER E-MAIL</strong><br />

				<strong>3.1 E-MAIL-NEWSLETTER MIT ANMELDUNG</strong><br />

				Wenn Sie sich zu unserem Newsletter anmelden, verwenden wir die hierfür erforderlichen oder gesondert von Ihnen mitgeteilten Daten, um Ihnen regelmäßig unseren E-Mail-Newsletter aufgrund Ihrer Einwilligung gemäß Art. 6 Abs. 1 S. 1 lit. a DSGVO zuzusenden.
				Die Abmeldung vom Newsletter ist jederzeit möglich und kann entweder durch eine Nachricht an die in dieser Datenschutzerklärung beschriebene Kontaktmöglichkeit oder über einen dafür vorgesehenen Link im Newsletter erfolgen. Nach Abmeldung löschen wir Ihre E-Mail-Adresse aus der Empfängerliste, soweit Sie nicht ausdrücklich in eine weitere Nutzung Ihrer Daten eingewilligt haben oder wir uns eine darüber hinausgehende Datenverwendung vorbehalten, die gesetzlich erlaubt ist und über die wir Sie in dieser Erklärung informieren.
			</p>
		</section>
		<section className="separator separator--bottom helper--tight">
			<p>
				<strong>3.2 NEWSLETTERVERSAND</strong><br />
				Der Newsletter wird gegebenenfalls auch durch unsere Dienstleister im Rahmen einer Verarbeitung in unserem Auftrag versendet. Bei Fragen zu unseren Dienstleistern und der Grundlage unserer Zusammenarbeit mit ihnen wenden Sie sich bitte an die in dieser Datenschutzerklärung beschriebenen Kontaktmöglichkeit.
			</p>
		</section>
		<section className="separator separator--bottom helper--tight">
			<p>
				<strong>4. KONTAKTMÖGLICHKEITEN UND IHRE RECHTE</strong><br />
				Als Betroffener haben Sie folgende Rechte:
			</p>
		</section>
		<section className="separator separator--bottom helper--tight">
			<p>
				* gemäß Art. 15 DSGVO das Recht, in dem dort bezeichneten Umfang Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten zu verlangen;<br />
				* gemäß Art. 16 DSGVO das Recht, unverzüglich die Berichtigung unrichtiger oder Vervollständigung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen;<br />
				* gemäß Art. 17 DSGVO das Recht, die Löschung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen, soweit nicht die weitere Verarbeitung * zur Ausübung des Rechts auf freie Meinungsäußerung und Information;<br />
				* zur Erfüllung einer rechtlichen Verpflichtung;<br />
				* aus Gründen des öffentlichen Interesses oder<br />
				* zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist;
			</p>
			<p>
				* gemäß Art. 18 DSGVO das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, soweit * die Richtigkeit der Daten von Ihnen bestritten wird;<br />
				* die Verarbeitung unrechtmäßig ist, Sie aber deren Löschung ablehnen;<br />
				* wir die Daten nicht mehr benötigen, Sie diese jedoch zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen oder<br />
				* Sie gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung eingelegt haben;
			</p>
			<p>
				* gemäß Art. 20 DSGVO das Recht, Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesebaren Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu verlangen;<br/>
				* gemäß Art. 77 DSGVO das Recht, sich bei einer Aufsichtsbehörde zu beschweren. In der Regel können Sie sich hierfür an die Aufsichtsbehörde Ihres üblichen Aufenthaltsortes oder Arbeitsplatzes oder unseres Unternehmenssitzes wenden.
			</p>
			<p>
				Bei Fragen zur Erhebung, Verarbeitung oder Nutzung Ihrer personenbezogenen Daten, bei Auskünften, Berichtigung, Einschränkung oder Löschung von Daten sowie Widerruf erteilter Einwilligungen oder Widerspruch gegen eine bestimmte Datenverwendung wenden Sie sich bitte direkt an uns über die Kontaktdaten in unserem Impressum.
			</p>
		</section>
		<section className="separator separator--bottom helper--tight">
			<p>
				<strong>Widerspruchsrecht</strong><br />
				Soweit wir zur Wahrung unserer im Rahmen einer Interessensabwägung überwiegenden berechtigten Interessen personenbezogene Daten wie oben erläutert verarbeiten, können Sie dieser Verarbeitung mit Wirkung für die Zukunft widersprechen. Erfolgt die Verarbeitung zu Zwecken des Direktmarketings, können Sie dieses Recht jederzeit wie oben beschrieben ausüben. Soweit die Verarbeitung zu anderen Zwecken erfolgt, steht Ihnen ein Widerspruchs- recht nur bei Vorliegen von Gründen, die sich aus Ihrer besonderen Situation ergeben, zu.
			</p>
			<p>
				Nach Ausübung Ihres Widerspruchsrechts werden wir Ihre personenbezogenen Daten nicht weiter zu diesen Zwecken verarbeiten, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder wenn die Verarbeitung der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen dient
			</p>
			<p>
				Dies gilt nicht, wenn die Verarbeitung zu Zwecken des Direktmarketings erfolgt. Dann werden wir Ihre personenbezogenen Daten nicht weiter zu diesem Zweck verarbeiten.
			</p>
		</section>
		<Back />
	</Layout>
);

export default Datenschutz;
